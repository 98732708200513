import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import CmsReportDetails from './cms-report-details';

const CmsReport = () => (
  <StaticQuery
    query={graphql`
      query MyQuery {
        allDirectusTeamMember(filter: { team_member_type: { team_type_name: { eq: "advisory-councils" } } }) {
          nodes {
            directusId
            team_first_name
            team_last_name
            slug
            team_job_title
            company
            team_bio
            advisory_council {
              name
            }
            team_photo {
              data {
                full_url
              }
            }
          }
        }
        allDirectusAdvisoryCouncilsSection {
          nodes {
            name
            value
          }
        }
      }
    `}
    render={(data: any) => <CmsReportDetails data={data} />}
  />
);

export default CmsReport;
