import React, { useState } from 'react';
import styled from '@emotion/styled';

import { StyledTable as TableForPagination } from '../../../components/table/table';
import { Table } from '../../../components/table';
import { Button } from '../../../components';
import { Toolbar } from '../../../components/table/toolbar';

export const ButtonBottom = styled.div`
  height: 15px;
`;
export const TableWrapperContainer = styled.div`
  width: 100%;
  @media print {
    * {
      display: none;
    }
  }
`;

export default function TableChart({
  config,
  onChangePagination,
  onChangeSearch,
}: {
  config: any;
  onChangePagination: (page: number, pageSize: number, csv?: boolean) => Promise<any>;
  onChangeSearch: (searchName: string) => Promise<any>;
}) {
  const [loadingCSV, setLoadingCSV] = useState(false);
  const generateCSV = (messageData: any) => {
    const csvData = [];
    const ids = messageData.columns.map((column: { dataIndex: any }) => column.dataIndex);
    csvData.push(messageData.columns.map((column: { title: any }) => column.title));
    csvData.push(
      ...messageData.data.map((element: any) => {
        return ids.map((id: string | number) => element[id]);
      })
    );

    return csvData.map((row) => row.join(',')).join('\n');
  };
  const generateLocalCSV = () => {
    const csv = generateCSV(config);

    const cvsBlob = new Blob([csv], { type: 'text/csv' });
    const downloadURL = URL.createObjectURL(cvsBlob);
    downloadCSV(downloadURL);
  };

  const getExternalCSVLink = async () => {
    setLoadingCSV(true);
    onChangePagination(0, 0, true)
      .then(({ downloadURL }) => {
        downloadCSV(downloadURL);
      })
      .finally(() => {
        setLoadingCSV(false);
      });
  };

  const downloadCSV = (downloadURL: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.download = `report.csv`;
    downloadLink.href = downloadURL;
    downloadLink.target = '_blank';
    downloadLink.onclick = () => document.body.removeChild(downloadLink);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  const handleChange = (newPagination: any) => {
    onChangePagination(newPagination.current, newPagination.pageSize);
  };

  const defaultPagination = {
    pageSize: 50,
    showSizeChanger: false,
  };

  const TableComponent = config.pagination ? TableForPagination : Table;

  return (
    <TableWrapperContainer>
      <ButtonBottom />
      <Button type="primary" onClick={config.pagination ? getExternalCSVLink : generateLocalCSV} loading={loadingCSV}>
        Download CSV
      </Button>
      <ButtonBottom />
      {config.pagination ? (
        <Toolbar
          toolbar={[]}
          options={{
            search: {
              onSearch: (searchText: string) => onChangeSearch(searchText),
            },
            pagination: {
              onChange: handleChange,
              ...defaultPagination,
              ...config.pagination,
            },
          }}
        />
      ) : (
        false
      )}
      <TableComponent
        dataSource={config.data}
        columns={config.columns}
        rowKey="id"
        tableLayout="fixed"
        pagination={config.pagination ? false : defaultPagination}
        scroll={{ x: config.columns.length * 200, y: '65vh' }}
      />
    </TableWrapperContainer>
  );
}
