import React from 'react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  margin: 24px 10px;
  height: 200px;
  width: 250px;
  border: solid 1px #dfe4ea;
  background: white;
  flex-direction: column;
  border-radius: 10px;
`;
export const Title = styled.div`
  font-size: 16px;
  border-bottom: 1px solid #dfe4ea;
  font-weight: bold;
  color: #000;
  padding: 10px;
`;
export const Content = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #000;
  align-self: center;
  padding-top: 46px;
`;

export default function SingleValueChart({ config }: any) {
  return (
    <Wrapper>
      <Title>{config.title}</Title>
      <Content>{config.data}</Content>
    </Wrapper>
  );
}
