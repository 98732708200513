import React, { useMemo, useCallback, useState } from 'react';
import { Row } from 'antd';
import styled from '@emotion/styled';

import BarChart from './charts/bar-chart';
import LineChart from './charts/line-chart';
import PieChart from './charts/pie-chart';
import Table from './charts/table';
import Map from './charts/map/map';
import SingleValue from './charts/single-value-chart';

import { Button } from '../../components';
import { getFullDataSetCsv } from '../../apis/admin/reports';
import { IReportFullDataSet, IReportTab } from '../../types/models/reports';

const NoResultsWrapper = styled.div`
  padding: 20% 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  p,
  div {
    text-align: center;
  }

  p {
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 5px;
  }
`;

const PrintButton = styled(Button)`
  &.ant-btn {
    @media print {
      & {
        display: none;
      }
    }
  }
`;
const DownloadCsvButton = styled(PrintButton)`
  margin-left: 16px;
`;
const ErrorMessageRow = styled(Row)`
  margin-top: 16px;
`;

export default function ReportTab({
  tabData,
  reportId,
  finalFormData,
  hasFullDataSet,
  onChangePagination,
  onChangeSearch,
}: {
  tabData?: IReportTab;
  reportId?: string;
  finalFormData: any;
  hasFullDataSet: boolean;
  onChangePagination: (page: number, pageSize: number, csv?: boolean) => Promise<any>;
  onChangeSearch: (string: string) => Promise<any>;
}) {
  const [isLoadingFullDataSet, setIsLoadingFullDataSet] = useState(false);
  const [fullDataSetError, setFullDataSetError] = useState(false);

  const showCharts = tabData && tabData.charts && tabData.charts.some((chart: any) => chart.data?.length > 0);

  const renderCharts = () => {
    if (showCharts) {
      return tabData.charts.map((chartConfig: any, index: number) => {
        switch (chartConfig.type) {
          case 'pie':
            return <PieChart config={chartConfig} key={`${chartConfig.title}-${index}`} />;
          case 'bar':
            return <BarChart config={chartConfig} key={`${chartConfig.title}-${index}`} />;
          case 'line':
            return <LineChart config={chartConfig} key={`${chartConfig.title}-${index}`} />;
          case 'table':
            return (
              <Table
                config={chartConfig}
                key={`${chartConfig.title}-${index}`}
                onChangePagination={onChangePagination}
                onChangeSearch={onChangeSearch}
              />
            );
          case 'map':
            return <Map config={chartConfig} key={`${chartConfig.title}-${index}`} />;
          case 'singleValue':
            return <SingleValue config={chartConfig} key={`${chartConfig.title}-${index}`} />;
        }
      });
    }
    return (
      <NoResultsWrapper>
        <p>No results</p>
        <div>Please try different search or filter criteria</div>
      </NoResultsWrapper>
    );
  };

  const isHidden = useMemo(
    () => tabData?.charts?.length && tabData?.charts?.some((chartConfig: any) => chartConfig.type === 'table'),
    [tabData]
  );

  const downloadFullDataSet = useCallback(async () => {
    setIsLoadingFullDataSet(true);
    setFullDataSetError(false);

    const reportFormData = finalFormData;

    try {
      const data: IReportFullDataSet = await getFullDataSetCsv(reportId, reportFormData);

      const downloadLink = document.createElement('a');
      downloadLink.download = `full-data-set.csv`;
      downloadLink.href = data.csvUrl;
      downloadLink.onclick = () => document.body.removeChild(downloadLink);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
    } catch {
      setFullDataSetError(true);
    } finally {
      setIsLoadingFullDataSet(false);
    }
  }, []);

  return (
    <>
      {showCharts && (
        <>
          <PrintButton type="primary" onClick={window.print} hidden={!!isHidden}>
            Download PDF
          </PrintButton>
          {hasFullDataSet && (
            <DownloadCsvButton
              type="primary"
              onClick={() => downloadFullDataSet()}
              hidden={!!isHidden}
              loading={isLoadingFullDataSet}
            >
              Download Full Data Set
            </DownloadCsvButton>
          )}
        </>
      )}
      {fullDataSetError && (
        <ErrorMessageRow gutter={32}>An error occurred while loading the full data set.</ErrorMessageRow>
      )}
      <Row gutter={32}>{renderCharts()}</Row>
    </>
  );
}
