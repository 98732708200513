import CmsReport from './cms-report';
import React from 'react';
import Report from './report';
import { RouteComponentProps } from '@reach/router';
import { StyledContainer } from '../myhsf/scholarship/common/styles';

type Props = RouteComponentProps<{ id?: string }>;

export const ReportDetail: React.FC<Props> = (props: Props) => {
  const renderContent = () => {
    const reportId = props.id;

    if (reportId === 'AdvisoryCouncilDirectory') {
      return <CmsReport />;
    } else {
      return <Report reportId={reportId} />;
    }
  };

  return <StyledContainer>{renderContent()}</StyledContainer>;
};

export default ReportDetail;
