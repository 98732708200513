import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import styled from '@emotion/styled';

import { Tile, Header, Title, TileContentWrapper, MarginCol } from '../../../components/common/dashboard-styles';

const NoResultsWrapper = styled.div`
  padding: 130px 24px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  p,
  div {
    text-align: center;
  }

  p {
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 5px;
  }
`;

export default function LineChart({ config }: any) {
  const renderChart = () => {
    const colors = [
      'rgb(141, 211, 199)',
      'rgb(207, 55, 245)',
      'rgb(190, 186, 218)',
      'rgb(251, 128, 114)',
      'rgb(228, 26, 28)',
      'rgb(55, 126, 184)',
      'rgb(77, 175, 74)',
      'rgb(152, 78, 163)',
      'rgb(255, 127, 0)',
      'rgb(255, 255, 51)',
      'rgb(166, 86, 40)',
      'rgb(247, 129, 191)',
      'rgb(153, 153, 153)',
    ];
    return config.data?.length > 0 && config.data?.some((item: any) => item.data?.length) ? (
      <ResponsiveLine
        data={config.data}
        margin={{ top: 30, right: 215, bottom: 30, left: 30 }}
        xScale={{
          type: 'time',
          format: '%d-%m-%Y',
        }}
        xFormat="time:%d-%m-%Y"
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
        yFormat=" >-.2f"
        colors={colors}
        axisTop={null}
        axisRight={null}
        enableGridX={false}
        axisBottom={{
          format: '%d %b',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        pointSize={8}
        pointLabelYOffset={12}
        enableSlices="x"
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 105,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 95,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 8,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    ) : (
      <NoResultsWrapper>
        <p>No results</p>
        <div>Please try different search or filter criteria</div>
      </NoResultsWrapper>
    );
  };

  return (
    <MarginCol span={config.width}>
      <Tile>
        <Header align="middle" justify="space-between">
          <Title>{config.title}</Title>
        </Header>
        <TileContentWrapper>
          <div style={{ height: config.height * 100 }}>{renderChart()}</div>
        </TileContentWrapper>
      </Tile>
    </MarginCol>
  );
}
