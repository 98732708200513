import React, { ReactNode, Fragment } from 'react';
import { RouteComponentProps } from '@reach/router';

type ReportsProps = RouteComponentProps & {
  children?: ReactNode;
};
const Context = React.createContext<any>({});
const ReportsContainer = (props?: ReportsProps) => (
  <Fragment>
    <Context.Provider value={{}}>{props?.children}</Context.Provider>
  </Fragment>
);

export default ReportsContainer;
