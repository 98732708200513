import { Col, List, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { StyledContainer, TitleRow } from '../myhsf/scholarship/common/styles';

import Loader from '../../components/common/loader';
import { RouteComponentProps } from '@reach/router';
import { getReport } from '../../apis/admin/reports';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import theme from '../../theme';

type ReportItem = {
  id: string;
  name: string;
};

const StyledDiv = styled.div`
  color: ${theme.colorsSecondaryBlue};
  font-size: 18px;
  line-height: 28px;
  text-decoration: underline;
  cursor: pointer;

  .ant-list-split .ant-list-item {
    border-bottom: none;
  }
`;

const StyledGroup = styled.div`
  width: 50%;

  .ant-list-split .ant-list-item {
    border-bottom: none;
  }

  .ant-list-lg .ant-list-item {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const StyledItem = styled(Row)`
  margin: 0;
`;

export const TitleCol = styled(Col)`
  color: ${theme.colorsBlack};
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  margin-top: 32px;
`;

export const ReportsGroupCol = styled(Col)`
  color: ${theme.colorsBlack};
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  margin: 16px 0 8px;
  padding: 0 !important;
`;

const ReportsList = (_: RouteComponentProps) => {
  const [reportListSchema, setReportListSchema] = useState<any>();
  const [error, setError] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    setError(false);

    try {
      const data: any = await getReport();

      setReportListSchema(data);
    } catch {
      setError(true);
    }
  }, []);

  const onHandleDetail = (id?: string) => {
    if (id === 'UserHistoryReport') {
      navigate(`/admin/reports/`);
    } else {
      navigate(`/reporting/${id}`);
    }
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (!reportListSchema) {
    return <Loader />;
  } else if (error) {
    return <p>Error</p>;
  }

  return (
    <>
      <StyledContainer>
        <TitleRow>
          <TitleCol>Reports</TitleCol>
        </TitleRow>
        <Row justify="center" gutter={32}>
          <Col xs={20} lg={24}>
            <div
              style={{
                marginBottom: '20px',
                display: 'flex',
                flexFlow: 'column wrap',
                height: '900px',
                width: '100%',
                overflow: 'hidden',
              }}
            >
              {Object.keys(reportListSchema).map(key => {
                return (
                  <StyledGroup key={key}>
                    <ReportsGroupCol>{key.replace(/([a-z])([A-Z])/g, `$1 $2`)}</ReportsGroupCol>
                    <List
                      itemLayout="vertical"
                      size="large"
                      dataSource={reportListSchema[key]}
                      renderItem={(item: ReportItem) => (
                        <List.Item>
                          <StyledItem>
                            <StyledDiv onClick={() => onHandleDetail(item.id)}>
                              {item.name.replace(/([a-z])([A-Z])/g, `$1 $2`)}
                            </StyledDiv>
                          </StyledItem>
                        </List.Item>
                      )}
                    />
                  </StyledGroup>
                );
              })}
            </div>
          </Col>
        </Row>
      </StyledContainer>
    </>
  );
};

export default ReportsList;
