import styled from '@emotion/styled';
import { Col, Form, Row } from 'antd';
import { Spin } from 'antd5';
import React, { useCallback, useEffect, useState } from 'react';
import { get, isString, set } from 'lodash';
import produce from 'immer';
import { Link } from 'gatsby';
import { isDayjs } from 'dayjs';

import ReportTab from './report-tab';

import { API_URL } from '../../constants';
import theme from '../../theme';
import Loader from '../../components/common/loader';
import { AdminContentLayoutType } from '../../types/layout';
import AdminContent from '../../components/admin/common/admin-content';
import PageHeader from '../../components/common/page-header';
import { DatePicker, FormItem, InputNumber, InputWithWrapper, Select } from '../../components/forms';
import { Button } from '../../components';
import { FormComponentType, IFormData } from '../../types/form';
import WithDataSource from '../../components/forms/form-render/with-data-source';
import TabNavigation, { ITabNavigationDataSourceItem } from '../../components/common/tab-navigation';
import { getReport, getReportFilters, getReportFromFP } from '../../apis/admin/reports';
import { IReport, IReportFilter, IReportTab } from '../../types/models/reports';
import {
  FinancialPortalReportEndpoints,
  FinancialPortalReportId,
  FinancialPortalReportsIds,
} from '../../components/admin/common/constants';

const StyledSelect = styled.div`
  & .ant-select-selection--multiple .ant-select-selection__choice__remove {
    right: 0;
    top: 6px;
  }
`;

const Container = styled.div`
  @media print {
    * {
      display: none;
    }
  }
  background-color: ${theme.colorsLightGrey};
  padding: 16px 24px;
  align-items: center;
  margin-bottom: 30px;

  .filter-spin {
    width: 100%;
    min-height: 100px;
  }
`;

const CustomTabWrapper = styled.div`
  & .ant-row {
    margin-right: 0;
    margin-left: 0;
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    padding-left: 16px;
  }

  & > .ant-row-flex {
    min-width: auto;
    min-height: 60vh;
  }

  & .ant-table-wrapper {
    max-width: 100%;
  }

  td.white-space-pre-wrap {
    white-space: pre-wrap;
  }

  td.padding-left:first-of-type {
    padding-left: 40px;
  }

  td.padding-left {
    padding-left: 16px;
  }

  td.border-bottom {
    border-bottom: 1px solid black;
  }

  tr:last-child {
    td.border-bottom {
      border-bottom: none;
    }
  }
`;

const StyledCol = styled(Col)`
  min-height: 155px;
`;

const PrintFiltersWrapper = styled.div`
  @media print {
    & {
      display: flex;
      flex-direction: column;
      align-items: start;
    }
  }

  h1 {
    font-size: 18px;
    font-weight: bold;
  }

  span {
    font-size: 16px;
    font-weight: bold;
    color: ${theme.colorsBlack};
  }

  display: none;
`;

export type ReportProps = {
  reportId?: string;
};

const InputTextPlaceholdersMap: { [key: string]: string } = {
  institution: 'Type Federal Code',
};

export default function Report({ reportId }: ReportProps) {
  const [reportSchema, setReportSchema] = useState<IReport>();
  const [formData, setFormData] = useState<IFormData>({});
  const [finalFormData, setFinalFormData] = useState<any>();
  const [tabData, setTabData] = useState<IReportTab>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [filters, setFilters] = useState<IReportFilter[]>([]);
  const [hasFullDataSet, setHasFullDataSet] = useState<boolean>(false);

  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(true);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);

  const [errorReport, setErrorReport] = useState<boolean>(false);
  const [errorFilters, setErrorFilters] = useState<boolean>(false);

  const getFinalFormData = (newFormData: IFormData, skipPagination = true) => {
    return Object.entries(newFormData).reduce((acc: any, [key, value]) => {
      if (!value) {
        return acc;
      }

      if (skipPagination && ['offset', 'limit', 'csv'].includes(key)) {
        return acc;
      }

      if (
        (isString(value) && !isDayjs(value)) ||
        (!isNaN(value) && value > 0) ||
        (Array.isArray(value) && value.length) ||
        isDayjs(value)
      ) {
        return { ...acc, [key]: value };
      }

      return acc;
    }, {});
  };

  const refreshFilters = useCallback(
    async (newFormData: IFormData, isLastFilter?: boolean) => {
      const newFinalFormData = getFinalFormData(newFormData);
      setFormData(newFormData);
      setFinalFormData(newFinalFormData);

      if (!isLastFilter) {
        setLoadingFilters(true);
        setErrorFilters(false);

        try {
          const filterResponse: IReportFilter[] = await getReportFilters(reportId, newFinalFormData);
          setFilters(filterResponse);
        } catch {
          setErrorFilters(true);
        }
        setLoadingFilters(false);
      }
    },
    [reportId]
  );

  const loadData = useCallback(
    async (resetData?: boolean, formData?: unknown) => {
      setLoadingReport(true);
      setErrorReport(false);

      let reportFormData = formData ?? finalFormData;

      if (resetData) {
        reportFormData = {};
        refreshFilters(reportFormData);
      }

      try {
        let data: IReport;
        if (reportId && FinancialPortalReportsIds.includes(reportId as FinancialPortalReportId)) {
          const reportEndpoint = FinancialPortalReportEndpoints[reportId as FinancialPortalReportId];
          data = await getReportFromFP(reportEndpoint, reportFormData);
        } else {
          data = await getReport(reportId, reportFormData);
        }
        setReportSchema(data);
        setTabData(data.data[tabIndex]);
        setHasFullDataSet(!!data.hasFullDataSet);
      } catch (err) {
        console.error('Error loading report:', err);
        setErrorReport(true);
      } finally {
        setLoadingPage(false);
        setLoadingReport(false);
      }
    },
    [reportId, finalFormData, tabIndex, setTabData, refreshFilters]
  );

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshFilters({});
  }, [refreshFilters]);

  const getFormData = useCallback(
    (path: string) => {
      return get(formData, path);
    },
    [formData]
  );

  const onChangeElement = useCallback(
    async (event: any, isLastFilter: boolean) => {
      const { name, value, path = name } = event;
      if (!name) {
        return;
      }

      const nextFormData = produce(formData, (draft) => {
        set(draft, path, value);
      });

      refreshFilters(nextFormData, isLastFilter);
    },
    [formData, refreshFilters]
  );

  const getExternalCSVLink = useCallback(async () => {
    const nextFormData = produce(formData, (draft) => {
      set(draft, 'csv', 'true');
    });
    try {
      let data: IReport;
      if (reportId && FinancialPortalReportsIds.includes(reportId as FinancialPortalReportId)) {
        const reportEndpoint = FinancialPortalReportEndpoints[reportId as FinancialPortalReportId];
        data = await getReportFromFP(reportEndpoint, nextFormData);
      } else {
        data = await getReport(reportId, nextFormData);
      }
      return data;
    } catch (err) {
      console.error('Error loading csv report:', err);
    }
  }, [formData, reportId]);

  const onChangePagination = useCallback(
    async (page: number, pageSize: number, csv?: boolean) => {
      if (csv) {
        return getExternalCSVLink();
      }
      const nextFormData = produce(formData, (draft) => {
        set(draft, 'limit', pageSize);
        set(draft, 'offset', (page - 1) * pageSize);
      });

      refreshFilters(nextFormData, false);
      const newFinalFormData = getFinalFormData(nextFormData, false);
      loadData(false, newFinalFormData);
    },
    [formData, getExternalCSVLink, loadData, refreshFilters]
  );

  const onChangeSearch = useCallback(
    async (search: string) => {
      const nextFormData = produce(formData, (draft) => {
        set(draft, 'search', search);
      });

      refreshFilters(nextFormData, false);
      const newFinalFormData = getFinalFormData(nextFormData, false);
      loadData(false, newFinalFormData);
    },
    [formData, loadData, refreshFilters]
  );

  const getFilterType = (filter: IReportFilter) => {
    switch (filter.type) {
      case 'text':
        return { type: FormComponentType.Text, Component: InputWithWrapper };
      case 'dropdown':
        return { type: FormComponentType.Dropdown, Component: Select };
      case 'date':
        return { type: FormComponentType.Date, Component: DatePicker };
      case 'number':
        return { type: FormComponentType.Number, Component: InputNumber };
    }
  };

  const renderFilters = () => {
    if (loadingPage) {
      return undefined;
    }

    const filtersComponents = errorFilters ? (
      <p>An error occurred while loading the filters.</p>
    ) : (
      <Spin spinning={!filters?.length} wrapperClassName="filter-spin">
        {filters.map((filter: IReportFilter, index: number) => {
          const { type, Component }: any = getFilterType(filter);

          const isLastFilter = index === filters.length - 1;
          const filterProps: any = {
            label: (
              <>
                <span>{filter.title}</span>
              </>
            ),
            getFormData,
            name: filter.name,
            id: `filter_${filter.name}`,
            validation: { type: 'string', rules: [] },
            onChange: (event: any) => onChangeElement(event, isLastFilter),
            value: getFormData(filter.name),
            loading: loadingFilters,
          };

          let filterComponent;

          if (filter.mode) {
            filterProps.mode = filter.mode;
            filterProps.showSearch = true;
            filterProps.optionFilterProp = 'children';
            filterProps.showArrow = true;
            filterProps.selectAllProperty = filterProps.mode === 'multiple';
          }

          if (filter.placeholder) {
            filterProps.placeholder = filter.placeholder;
          } else if (filter.type === 'text') {
            filterProps.placeholder = InputTextPlaceholdersMap[filter.name] ?? 'Write something';
          } else {
            if (filter.type === 'text') {
              filterProps.placeholder = 'Write something';
            } else {
              filterProps.placeholder = filterProps.mode === 'multiple' ? 'Select one or more' : 'Select one';
            }
          }

          if (type === FormComponentType.Date) {
            filterProps.allowClear = false;
          }

          if (type === FormComponentType.Number) {
            filterProps.min = 1;
            filterProps.type = type;
          }

          if (filter.params) {
            filterProps.options = filter.params;
            filterComponent = <Component {...filterProps} />;
          } else if (filter.dataSource) {
            filterProps.dataSource = filter.dataSource;
            if (filter.dataSource.url && filter.dataSource.url.slice(0, 4) != 'http') {
              filter.dataSource.url = `${API_URL}/${filter.dataSource.url}`;
            }

            filterComponent = (
              <WithDataSource type={type} {...filterProps}>
                <Component selectAllProperty={filterProps.mode === 'multiple'} />
              </WithDataSource>
            );
          } else {
            filterComponent = <Component {...filterProps} />;
          }
          return (
            <StyledCol key={index} span={8}>
              <FormItem label={filter.title}>
                <StyledSelect>{filterComponent}</StyledSelect>
              </FormItem>
            </StyledCol>
          );
        })}
      </Spin>
    );

    const getParamLabel = (selectedParams: any[], params: { value: string; label: string }[] | undefined) => {
      return selectedParams.reduce((arr, value) => {
        if (!params) {
          return;
        }
        const selectedField = params.find((item) => item.value === value);
        selectedField && arr.push(` ${selectedField.label}`);
        return arr;
      }, []);
    };

    // TODO -- this doesn't even work
    const getPrintFiltersData = () => {
      return Object.entries(finalFormData).length ? (
        Object.entries(finalFormData).map(
          (arr) =>
            arr.includes('programsIds') &&
            arr.map((selectedParams, key) => {
              const params: { value: string; label: string }[] | undefined = filters[key].params;
              const labelFields = Array.isArray(selectedParams) && getParamLabel(selectedParams, params).toString();
              return (
                <>
                  <span key={`${new Date().getTime()}=${key}`}>
                    {Array.isArray(selectedParams) ? labelFields : selectedParams}
                    {key % 2 === 0 ? ': ' : ''}
                  </span>
                  {key % 2 === 0 ? '' : <br />}
                </>
              );
            })
        )
      ) : (
        <span>-</span>
      );
    };

    const printFilters = () => {
      return (
        <Row>
          <Col>
            <PrintFiltersWrapper>
              <h1>Filters: </h1>
              <div>{getPrintFiltersData()}</div>
            </PrintFiltersWrapper>
          </Col>
        </Row>
      );
    };

    return (
      <>
        <Container>
          <Form>
            <Row align="top" gutter={32}>
              {filtersComponents}
            </Row>
          </Form>
          <Row justify="end" gutter={32} align="middle">
            <Col span={2}>
              <Button type="link" onClick={() => loadData(true)}>
                Clear Filter
              </Button>
            </Col>
            <Col span={4}>
              <Button type="primary" loading={loadingReport} onClick={() => loadData()}>
                Apply Filter
              </Button>
            </Col>
          </Row>
        </Container>
        {printFilters()}
      </>
    );
  };

  const getTabDataSource = (source: IReportTab[]) => {
    return source.map((tab: IReportTab, index: number) => ({
      label: tab.title,
      value: index.toString(),
    }));
  };

  const onTabChange = useCallback(
    (item: ITabNavigationDataSourceItem) => {
      setTabData(reportSchema?.data[item.value]);
      setTabIndex(Number(item.value));
    },
    [reportSchema]
  );

  const renderTabs = () => {
    if (loadingPage || errorReport) {
      return undefined;
    }

    const data = get(reportSchema, 'data', []);
    const tabDataSource = getTabDataSource(data);

    return tabDataSource && tabDataSource.length > 0 ? (
      <TabNavigation dataSource={tabDataSource} onTabChange={onTabChange} />
    ) : undefined;
  };

  const renderContent = () => {
    if (errorReport) {
      return <p>Error</p>;
    } else if (loadingPage || loadingReport || !reportSchema) {
      return <Loader />;
    } else {
      return (
        <ReportTab
          tabData={tabData}
          reportId={reportId}
          finalFormData={finalFormData}
          hasFullDataSet={hasFullDataSet}
          onChangePagination={
            onChangePagination as unknown as (page: number, pageSize: number, csv?: boolean) => Promise<any>
          }
          onChangeSearch={onChangeSearch}
        />
      );
    }
  };

  const reportTitle = reportSchema ? reportSchema.title : reportId?.replace(/([A-Z])/g, ' $1').trim();

  return (
    <>
      <PageHeader
        title={reportTitle}
        breadcrumb={[
          <Link key="1" to="/">
            Home
          </Link>,
          <Link key="2" to="/reporting">
            Reporting
          </Link>,
          reportTitle,
        ]}
        extra={
          <>
            {renderFilters()}
            {renderTabs()}
          </>
        }
      />
      <CustomTabWrapper>
        <AdminContent type={AdminContentLayoutType.TABLE}>{renderContent()}</AdminContent>
      </CustomTabWrapper>
    </>
  );
}
